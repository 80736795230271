export enum CrossGradeTypeEnum {
  OFF_70 = 'OFF_70',
  NOOM_UPDATE = 'NOOM_UPDATE',
  NOOM_UPDATE_SPECIAL = 'NOOM_UPDATE_SPECIAL',
  UPDATE_FROM_MONTH = 'UPDATE_FROM_MONTH',
  UPDATE_FROM_MONTH_AFTER_ADDITIONAL = 'UPDATE_FROM_MONTH_AFTER_ADDITIONAL',
  UPDATE_FROM_3MONTHS = 'UPDATE_FROM_3MONTHS',
  UPDATE_FROM_3MONTHS_AFTER_ADDITIONAL = 'UPDATE_FROM_3MONTHS_AFTER_ADDITIONAL',
  UPDATE_FROM_6MONTHS = 'UPDATE_FROM_6MONTHS',
  UPDATE_FROM_6MONTHS_AFTER_ADDITIONAL = 'UPDATE_FROM_6MONTHS_AFTER_ADDITIONAL',
}
