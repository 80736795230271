import { AnalyticTypesEnum } from 'src/app/common/enums/analytic-types.enum';
import { AnalyticService } from 'src/app/common/services/analytic.service';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { StorageService } from 'src/app/common/services/storage.service';

import { AppService } from './app.service';
import { DEFAULT_VARIANT } from './common/constants/default-variant';
import { LOCALIZATIONS } from './common/constants/languages';
import { SOURCES } from './common/constants/sourses';
import { Variant } from './common/models/variant';
import { SubscriptionService } from './common/services/subscription.service';
import { SetSource } from './store/actions/user-info.actions';
import { UpdateAndChangeVariant } from './store/actions/variant.actions';
import { selectAllData } from './store/selectors/app.selector';
import { IAppState } from './store/states/app.state';

declare const window: any;

@Component({
  selector: 'ik-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  public allDataObservable = this.store.pipe(select(selectAllData));

  public allData: IAppState | null = null;

  private destroy = new Subject<void>();

  constructor(
    private readonly appService: AppService,
    private readonly translate: TranslateService,
    private readonly store: Store<IAppState>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly subscriptionService: SubscriptionService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly analyticService: AnalyticService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    translate.setDefaultLang('en');
  }

  public ngOnInit(): void {
    this.appService.setVariant();
    this.subscriptionStore();
    setTimeout(() => this.setDefaultVariant(), 2500);
    this.getLangFromRoute();
    this.appService.updateTag();
    this.appService.checkCookie();
    this.checkTranslationReady();
    this.langChangeSubscription();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public ngAfterContentInit(): void {
    this.appService.insertFonts();
  }

  private checkTranslationReady(): void {
    this.translate
      .stream('Button_Continue')
      .pipe(takeUntil(this.destroy))
      .subscribe(answer =>
        answer !== 'Button_Continue'
          ? this.closeLoader()
          : ''
      );
  }

  private closeLoader(): void {
    const loader = this.document.getElementById('row');
    if (loader) {
      loader.style.opacity = '0';
      setTimeout(() => (loader.style.display = 'none'), 500);
    }
  }

  private setDefaultVariant(): void {
    if (!this.allData || !this.allData!.variant.variant) {
      this.store.dispatch(new UpdateAndChangeVariant(DEFAULT_VARIANT));
    }
  }

  private setInitialLang(langFromRoute: string | null, isLang: boolean): void {
    if (
      (window.location.pathname.indexOf('/program_1') !== -1 &&
        window.location.pathname.indexOf('/program_1_local') === -1) ||
      window.location.pathname.indexOf('/flow_001') !== -1 ||
      window.location.pathname.indexOf('/flow_002') !== -1 ||
      window.location.pathname.indexOf('/flow_us_') !== -1 ||
      window.location.pathname.indexOf('/tiktok') !== -1 ||
      window.location.pathname.indexOf('/new_product ') !== -1 ||
      window.location.pathname.indexOf('/flow_ww ') !== -1 ||
      window.location.pathname.indexOf('/flow_ca ') !== -1
    ) {
      this.translate.use('en');
      return;
    }
    if (window.location.pathname.indexOf('/flow_de_001') !== -1) {
      this.translate.use('de');
      return;
    }

    if (window.location.pathname.indexOf('/flow_fr') !== -1) {
      this.translate.use('fr');
      return;
    }

    if (window.location.pathname.indexOf('/flow_it') !== -1) {
      this.translate.use('it');
      return;
    }

    if (window.location.pathname.indexOf('/flow_br_') !== -1) {
      this.translate.use('pt-BR');
      return;
    }

    if (window.location.pathname.indexOf('/inst-es') !== -1) {
      this.translate.use('es-MX');
      return;
    }

    if (window.location.pathname.indexOf('/flow_es') !== -1) {
      if (this.allData && this.allData.variant.variant && this.allData.variant.variant.parameters.alternativeTranslate) {
        this.translate.use('es-MX2');
        return;
      }
      this.translate.use('es-MX');
      return;
    }

    if (window.location.pathname.indexOf('/want_to_know_tid') !== -1) {
      this.translate.use('kl');
      return;
    }

    if (langFromRoute && isLang) {
      const usageLang = this.getExistLang(langFromRoute);
      this.translate.use(usageLang);
      return;
    }
    const browserLang = this.translate.getBrowserLang()!;
    const usageLang = this.getExistLang(browserLang);
    this.translate.use(
      browserLang.match(/en|nl|fr|it|de|sv|id|de|no|es/)
        ? browserLang
        : usageLang
    );
  }

  private getExistLang(str: string): string {
    const foundedLang = LOCALIZATIONS.find(
      item => item.code.indexOf(str) !== -1
    );
    return foundedLang ? foundedLang.code : 'en';
  }

  private getLangFromRoute(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        takeUntil(this.destroy),
        map(route => {
          while (route.firstChild) {
            // tslint:disable-next-line: no-parameter-reassignment
            route = route.firstChild;
          }
          return route;
        }),
        first()
      )
      .subscribe(route => {
        const param1 = route.snapshot.params.param1;
        const param2 = route.snapshot.params.param2;
        const param3 = route.snapshot.params.param3;
        const param1IsLang = !!LOCALIZATIONS.find(item => item.code === param1);
        const param1IsSource = SOURCES.has(param1);
        const param2IsSource = SOURCES.has(param2);
        if (!this.translate.currentLang) {
          this.setInitialLang(param1, param1IsLang);
        }

        if (param1IsSource) {
          this.store.dispatch(new SetSource(param1));
        }

        if (param2IsSource) {
          this.store.dispatch(new SetSource(param2));
        }

        this.paramIsEmail(param1);
        this.paramIsEmail(param2);
        this.paramIsEmail(param3);
      });
  }

  private paramIsEmail(param: string): void {
    if (!param) return;
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (param.match(regex)) {
      this.appService.authByEmail(param);
    }
  }

  private getProductsNowOrLater(variant: Variant): void {
    const pathname = window.location.pathname;
    if (pathname.length > 2) {
      this.getProducts(variant);
    } else {
      setTimeout(() => this.getProducts(variant), 3000);
    }
  }

  private getProducts(variant: Variant): void {
    this.subscriptionService
      .getFlagAndProducts(variant)
      .pipe(takeUntil(this.destroy))
      .subscribe();
    this.subscriptionService
      .getLifetimeProducts()
      .pipe(takeUntil(this.destroy))
      .subscribe();
    this.getPaypalDisabled();
    this.getCardHolderVisible();
    this.getApplePurchase();
  }

  private getPaypalDisabled(): void {
    this.subscriptionService
      .getPaypalDisabled()
      .pipe(takeUntil(this.destroy))
      .subscribe();
  }

  private getCardHolderVisible(): void {
    this.subscriptionService
      .getCardHolderVisible()
      .pipe(takeUntil(this.destroy))
      .subscribe();
  }

  private getApplePurchase(): void {
    this.subscriptionService
      .getApplePurchaseValue()
      .pipe(takeUntil(this.destroy))
      .subscribe();
  }

  private subscriptionStore(): void {
    this.allDataObservable
      .pipe(
        takeUntil(this.destroy),
        filter(data => !!data && !!data.variant.variant),
        tap(data => {
          if (!this.allData || !this.allData!.variant.variant) {
            if (data!.variant.variant!.parameters.alternativeTranslate && window.location.pathname.indexOf('/flow_es') !== -1) {
              this.translate.use('es-MX2');
            }
            this.getProductsNowOrLater(data!.variant.variant!);
            this.checkLangIgnoreList(data!.variant.variant!);
            if (data!.variant.variant) {
              this.storageService.setVariantName(data!.variant.variant!.name);
            }
          }
          this.allData = data;
          this.changeDetectorRef.markForCheck();
        }),
        first(),
        tap(() =>  this.firstAnalyticEvent())
      )
      .subscribe();
  }

  private firstAnalyticEvent(): void {
    const time = (new Date().getTime() - window.timerStart).toString();
    const properties: any = {
      download_time: time,
      download_start_time: new Date(window.timerStart).toString(),
      download_end_time: new Date().toString(),
    };
    this.analyticService
      .analyticWebPoint(AnalyticTypesEnum.WebOnboardingView, properties)
      .subscribe();
  }

  private langChangeSubscription(): void {
    this.translate.onLangChange.subscribe(() => {
      if (this.allData && this.allData.variant.variant) {
        this.checkLangIgnoreList(this.allData.variant.variant);
      }
      this.document.documentElement.lang = this.translate.currentLang;
    });
  }

  private checkLangIgnoreList(variant: Variant): void {
    if (!variant.parameters || !variant.parameters.listDisabledLocal) return;
    if (
      variant.parameters.listDisabledLocal.find(
        (item: string) => item === this.translate.currentLang
      )
    ) {
      this.translate.use('en');
    }
  }
}
