import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AddCardLink, AddCardLinkSuccess, ClearPaymentLink, ClearPaymentLinkSuccess, EPaymentLinkActions } from '../actions/payment-link.actions';

import { PaymentLink } from './../../common/models/payment-link';
import { AddPaypalLink, AddPaypalLinkSuccess } from './../actions/payment-link.actions';

@Injectable()
export class PaymentLinkEffects {
  public updateCardLinks = createEffect(() => {
    return this.actions.pipe(
      ofType<AddCardLink>(EPaymentLinkActions.AddCardLink),
      map(params => params.payload),
      switchMap((link: PaymentLink) => {
        return of(new AddCardLinkSuccess(link));
      }),
    );
  });

  public updatePaypalLinks = createEffect(() => {
    return this.actions.pipe(
      ofType<AddPaypalLink>(EPaymentLinkActions.AddPaypalLink),
      map(params => params.payload),
      switchMap((link: PaymentLink) => {
        return of(new AddPaypalLinkSuccess(link));
      }),
    );
  });

  public clearPaymentLinks = createEffect(() => {
    return this.actions.pipe(
      ofType<ClearPaymentLink>(EPaymentLinkActions.ClearPaymentLink),
      map(params => params),
      switchMap(() => {
        return of(new ClearPaymentLinkSuccess());
      }),
    );
  });

  constructor(
    private actions: Actions,
  ) {}
}
