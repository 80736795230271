import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAppState } from 'src/app/store/states/app.state';
import { IframeDataJson } from 'src/app/ui/pay/common/iframe-data.json-interface';

import { SubscriptionStatusesEnum } from '../enums/subscription-statuses.enum';
import { EmailDataJson } from '../json/email-data.json-interface';

import { selectAllData } from './../../store/selectors/app.selector';
import { ActiveSubscriptionsJson } from './../json/active-subscriptions.json-interface';
import { PaymentPlanJson } from './../json/payment-plan.json-interface';
import { ProductJson } from './../json/product.json-interface';
import { ActiveSubscription } from './active-subscription';
import { Payment } from './payment';
import { Product } from './product';

@Injectable()
export class ProductModelsFactory {

  public allDataObservable = this.store.pipe(select(selectAllData));

  public allData!: IAppState;

  private destroy = new Subject<void>();

  constructor(
    private store: Store<IAppState>,
    private translate: TranslateService,
  ) {
    this.subscriptionStore();
  }

  public getProduct(json: ProductJson): Product {
    return new Product(
      json.product_id,
      json.price_cents,
      json.projects,
      json.period_in_weeks,
      json.currency,
      json.old_price_cents,
      this.getTrialByPaymentPlan(json.payment_plan),
      json.trial_price_cents,
      this.getDaysOfTrial(json.payment_plan),
      json.product_name,
      this.getPeriodInDaysByProduct(json),
      json.payment_plan,
      json.country,
      json.public_description,
    );
  }

  public getPaymentByEvent(data: IframeDataJson | null, type: 'paypal' | 'card' | 'google'): Payment {
    const product = this.allData.userInfo.product!;
    const options:  Intl.DateTimeFormatOptions = { month: 'long', day: '2-digit', year: 'numeric' };
    return new Payment(
      product.trialDays ? product.trialDays > 7 ? product.trialPriceCents! / 100 : product.priceCents / 100 * 0.3 : product.priceCents / 100,
      product.priceCents / 100,
      product.currency,
      'IntellectoKids',
      new Date().toLocaleDateString('en', options),
      product.id,
      'Secret',
      product.trialDays,
      product.country,
      type,
      this.allData.userInfo.email!,
    );
  }

  public getTrialByPaymentPlan(plan: PaymentPlanJson): string {
    if (!plan || !plan.trial_period_duration) {
      return '';
    }
    switch (plan.trial_period_duration) {
      case 'DAY': return `${plan.trial_period_amount}-day`;
      case 'WEEK': return `${plan.trial_period_amount}-week`;
      case 'MONTH' : return `${plan.trial_period_amount}-month`;
    }
  }

  public getDaysOfTrial(plan: PaymentPlanJson): number {
    if (!plan || !plan.trial_period_duration) {
      return 0;
    }
    switch (plan.trial_period_duration) {
      case 'DAY': return plan.trial_period_amount;
      case 'WEEK': return plan.trial_period_amount * 7;
      case 'MONTH': return plan.trial_period_amount * 30;
    }
  }

  public getAllSubscriptions(json: ActiveSubscriptionsJson): ActiveSubscription[] {
    return json.solid_subscriptions.map(item => {
      return new ActiveSubscription(
        item.product_id,
        item.projects,
        new Date(item.expired_at_unix_sec * 1000),
        item.canceled_at_unix_sec ? new Date(item.canceled_at_unix_sec * 1000) : null,
        item.trial ? item.trial : null,
        item.started_at_unix_sec ? new Date(item.started_at_unix_sec * 1000) : null,
        item.next_service_fee_at_unix_sec ? new Date(item.next_service_fee_at_unix_sec * 1000) : null,
        item.status,
        item.expired_at_unix_sec,
        item.last_payment_at_unix_sec,
        item.resources,
        item.payment_plan ? {
          periodAmount: item.payment_plan.period_amount,
          periodDuration: item.payment_plan.period_duration,
          trialPeriodAmount: item.payment_plan.trial_period_amount,
          trialPeriodDuration: item.payment_plan.trial_period_duration,
        } : null,
        item.subscription_id,
        item.product_name,
      );
    }).sort((a, b) => {
      if (a.status === SubscriptionStatusesEnum.ACTIVE) {
        return -1;
      }
      if (b.status === SubscriptionStatusesEnum.ACTIVE) {
        return 1;
      }
      return 0;
    });
  }

  public getEmailDataJson(payment: Payment, product: Product): EmailDataJson {
    return {
      payment_date: moment(new Date(payment.paymentDate)).format('DD-MM-YYYY'),
      purchased_plan: `IntellectoKids(${ payment.purchasedPlan })`,
      plan_paid_until: moment(new Date(payment.planPaidUntil)).format('DD-MM-YYYY'),
      email: payment.email,
      transaction_id: payment.transactionId,
      amount: payment.amount.toFixed(2).toString(),
      currency: payment.currency,
      duration: this.getPeriodByProduct(product),
      language_update_json: JSON.stringify({ languageCode: this.translate.currentLang }),
    };
  }

  public getPriceWithPeriodByProduct(product: Product): string {
    const currency = product.currency === 'USD' ? '$' : product.currency;
    const amount = product.priceCents / 100;
    const period = this.getPeriodByProduct(product);

    return `${currency} ${amount} / ${period}`;
  }

  public getPeriodByProduct(product: Product): string {
    switch (product.periodInDays) {
      case 7: return 'week';
      case 30: return 'month';
      case 60: return '2 months';
      case 90: return '3 months';
      case 180: return '6 months';
      case 365: return 'year';
    }
    return `${product.paymentPlan.period_amount} ${product.paymentPlan.period_duration.toLocaleLowerCase()}`;
  }

  public defineDisplayedProducts(): Product[] {
    let secondProduct!: Product;
    let firstProduct: Product;
    const products = this.allData.product.products;
    const subscriptions = this.allData.subscription.subscriptions;
    let findFirstStage = products!.find(item => item.id === 'b0ca249a-eb1a-4fa5-a6d9-f7ced0fc0f23');
    const findSecondStage = products!.find(item => item.id === '51734018-6553-4c03-8cd0-b113f1c347e1');
    if (subscriptions!.length) {
      findFirstStage = products!.find(item => item.id === 'a219e2e1-d194-441b-8c54-934e3e3a8434');
    }
    secondProduct = findSecondStage ? findSecondStage : products![0];
    firstProduct = findFirstStage ? findFirstStage : products![2];
    const findSecond = products!.find(item => item.id === 'b5b1f595-328a-4fc8-9e6e-be8d4f9a3163');
    let findFirst = products!.find(item => item.id === '2256f242-0930-4e3f-b7bc-14746d901ee5');
    if (subscriptions!.length) {
      findFirst = products!.find(item => item.id === '9950ab13-9fa7-486a-bbb4-2f9f740efa51');
    }
    secondProduct = findSecondStage ? findSecondStage : findSecond!;
    firstProduct = findFirstStage ? findFirstStage : findFirst!;
    return [secondProduct, firstProduct];
  }

  private getPeriodInDaysByProduct(product: ProductJson): number {
    if (!product.payment_plan) {
      return 0;
    }

    let factor = 0;

    switch (product.payment_plan.period_duration) {
      case 'MONTH': {
        factor = 30;
        break;
      }
      case 'WEEK': {
        factor = 7;
        break;
      }
      case 'YEAR': {
        factor = 365;
        break;
      }
      case 'DAY' : {
        factor = 1;
      }
    }

    return factor * product.payment_plan.period_amount;
  }

  private subscriptionStore(): void {
    this.allDataObservable
      .pipe(takeUntil(this.destroy))
      .subscribe(data => this.allData = data!);
  }
}
