import { Action } from '@ngrx/store';

import { Product } from './../../common/models/product';

export enum EProductActions {
  UpdateProducts = '[Product] Update Products',
  UpdateProductsSuccess = '[Product] Get Products Success',
  UpdateLifeTimeProducts = '[Product] Update Lifetime Products',
  UpdateLifeTimeProductsSuccess = '[Product] Update Lifetime Products Success',
  UpdateTwinsSupported = '[Product] Twins Supported',
  UpdatePaypalDisabled = '[Product] Paypal Disabled',
  UpdateCardHolderVisible = '[Product] Card Holder Visible',
  UpdateApplePurchase = '[Product] Apple Purchase',
}

export class UpdateProducts implements Action {
  public readonly type = EProductActions.UpdateProducts;
  constructor(public payload: Product[]) {}
}

export class UpdateProductsSuccess implements Action {
  public readonly type = EProductActions.UpdateProductsSuccess;
  constructor(public payload: Product[]) {}
}

export class UpdateLifetimeProducts implements Action {
  public readonly type = EProductActions.UpdateLifeTimeProducts;
  constructor(public payload: Product[]) {}
}

export class UpdateLifetimeProductsSuccess implements Action {
  public readonly type = EProductActions.UpdateLifeTimeProductsSuccess;
  constructor(public payload: Product[]) {}
}

export class UpdateTwinsSupported implements Action {
  public readonly type = EProductActions.UpdateTwinsSupported;
  constructor(public payload: boolean) {}
}

export class UpdatePaypalDisabled implements Action {
  public readonly type = EProductActions.UpdatePaypalDisabled;
  constructor(public payload: boolean) {}
}

export class UpdateCardHolderVisible implements Action {
  public readonly type = EProductActions.UpdateCardHolderVisible;
  constructor(public payload: boolean) {}
}

export class UpdateApplePurchase implements Action {
  public readonly type = EProductActions.UpdateApplePurchase;
  constructor(public payload: boolean) {}
}

export type ProductActions =
UpdateProducts |
UpdateProductsSuccess |
UpdateLifetimeProducts |
UpdateLifetimeProductsSuccess |
UpdateTwinsSupported |
UpdatePaypalDisabled |
UpdateCardHolderVisible |
UpdateApplePurchase;
