import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {

  private countryList = new Set([
    'AUS',
    'CAN',
    'MEX',
    'BRA',
    'ARG',
    'PER',
    'DOM',
    'CRI',
    'URY',
    'ECU',
    'PRY',
    'PAN',
    'BHS',
    'BRB',
    'PRI',
    'ATG',
    'GRD',
    'ABW',
    'DMA',
    'HTI',
    'GLP',
    'MTQ',
    'AIA',
    'MSR',
    'TTO',
    'JAM',
    'GUY',
    'CYM',
    'VEN',
    'CUW',
    'GTM',
    'BOL',
    'BMU',
    'SLV',
    'SUR',
    'BLZ',
    'NIC',
    'HND',
    'GUF',
  ]);

  constructor(private currencyPipe: CurrencyPipe) {}

  public transform(value: number, currency: string, display: string, country = 'USA'): any {
    if (currency === 'CAD' || currency === 'AUD') {
      return this.currencyPipe.transform(value, currency, 'symbol');
    }
    if ((this.countryList.has(country)) && currency === 'USD') {
      if (!value) {
        return 'US$';
      }
      return `US$${(value * 1).toFixed(2)}`;
    }
    return this.currencyPipe.transform(value, currency, display);
  }

}
