import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { EUserInfoActions } from '../actions/user-info.actions';

import {
  GetUserInfoAuth,
  GetUserInfoAuthSuccess,
  GetUserInfoBrowserID,
  GetUserInfoBrowserIDSuccess,
  GetUserInfoChallenge,
  GetUserInfoChallengeSuccess,
  GetUserInfoEmail,
  GetUserInfoEmailSuccess,
  GetUserInfoPayment,
  GetUserInfoPaymentSuccess,
  GetUserInfoProduct,
  GetUserInfoProductSuccess,
  GetUserInfoPromoter,
  GetUserInfoPromoterSuccess,
  GetUserInfoUpgrade,
  GetUserInfoUpgradeSuccess,
} from './../actions/user-info.actions';

@Injectable()
export class UserInfoEffects {

  public getUserInfoPayment = createEffect(() => {
    return this.actions.pipe(
      ofType<GetUserInfoPayment>(EUserInfoActions.GetUserInfoPayment),
      map(params => params.payload),
      switchMap(payment => {
        return of(new GetUserInfoPaymentSuccess(payment));
      }),
    );
  });

  public getUserInfoEmail = createEffect(() => {
    return this.actions.pipe(
      ofType<GetUserInfoEmail>(EUserInfoActions.GetUserInfoEmail),
      map(params => params.payload),
      switchMap(email => {
        return of(new GetUserInfoEmailSuccess(email!));
      }),
    );
  });

  public getUserInfoProduct = createEffect(() => {
    return this.actions.pipe(
      ofType<GetUserInfoProduct>(EUserInfoActions.GetUserInfoProduct),
      map(params => params.payload),
      switchMap(product => {
        return of(new GetUserInfoProductSuccess(product));
      }),
    );
  });

  public getUserInfoChallenge = createEffect(() => {
    return this.actions.pipe(
      ofType<GetUserInfoChallenge>(EUserInfoActions.GetUserInfoChallenge),
      map(params => params.payload),
      switchMap(challenge => {
        return of(new GetUserInfoChallengeSuccess(challenge));
      }),
    );
  });

  public getUserInfoAuth = createEffect(() => {
    return this.actions.pipe(
      ofType<GetUserInfoAuth>(EUserInfoActions.GetUserInfoAuth),
      map(params => params.payload),
      switchMap(auth => {
        return of(new GetUserInfoAuthSuccess(auth));
      }),
    );
  });

  public getUserInfoBrowserID = createEffect(() => {
    return this.actions.pipe(
      ofType<GetUserInfoBrowserID>(EUserInfoActions.GetUserInfoBrowserID),
      map(params => params.payload),
      switchMap(screen => {
        return of(new GetUserInfoBrowserIDSuccess(screen));
      }),
    );
  });

  public getUserInfoPromoter = createEffect(() => {
    return this.actions.pipe(
      ofType<GetUserInfoPromoter>(EUserInfoActions.GetUserInfoPromoter),
      map(params => params.payload),
      switchMap(data => {
        return of(new GetUserInfoPromoterSuccess(data));
      }),
    );
  });

  public getUserInfoUpgrade = createEffect(() => {
    return this.actions.pipe(
      ofType<GetUserInfoUpgrade>(EUserInfoActions.GetUserInfoUpgrade),
      map(params => params.payload),
      switchMap(data => {
        return of(new GetUserInfoUpgradeSuccess(data));
      }),
    );
  });

  constructor(
    private actions: Actions,
  ) {}
}
