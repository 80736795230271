import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Product } from '../../common/models/product';
import { EProductActions, UpdateProducts, UpdateProductsSuccess } from '../actions/product.actions';

@Injectable()
export class ProductEffects {
  public updateProducts = createEffect(() => {
    return this.actions.pipe(
      ofType<UpdateProducts>(EProductActions.UpdateProducts),
      map(params => params.payload),
      switchMap((products: Product[]) => {
        return of(new UpdateProductsSuccess(products));
      }),
    );
  });

  constructor(
    private actions: Actions,
  ) {}
}
