import { EProductActions, ProductActions } from '../actions/product.actions';
import { initialProductState, IProductState } from '../states/product.state';

export function productReducers(
  state = initialProductState,
  action: ProductActions,
): IProductState {
  switch (action.type) {
    case EProductActions.UpdateProductsSuccess: {
      return {
        ...state,
        products: action.payload,
      };
    }

    case EProductActions.UpdateLifeTimeProductsSuccess: {
      return {
        ...state,
        lifetimeProducts: action.payload,
      };
    }

    case EProductActions.UpdateTwinsSupported: {
      return {
        ...state,
        twinsSupported: action.payload,
      };
    }

    case EProductActions.UpdatePaypalDisabled: {
      return {
        ...state,
        paypalDisabled: action.payload,
      };
    }

    case EProductActions.UpdateCardHolderVisible: {
      return {
        ...state,
        isCardHolderVisible: action.payload,
      };
    }

    case EProductActions.UpdateApplePurchase: {
      return {
        ...state,
        applePurchaseOn: action.payload,
      };
    }

    default : return state;
  }
}
