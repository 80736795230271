export class Payment {
  constructor(
    public value: number,
    public amount: number,
    public currency: string,
    public descriptor: string | null,
    public paymentDate: string,
    public productId: string,
    public transactionId: string,
    public trial: number | null,
    public country: string,
    public email: string = '',
    public purchasedPlan: string = '',
    public planPaidUntil: string = '',
  ) {}
}
