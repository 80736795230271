import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as JSLZString from 'lz-string';
import { GetUserInfoBrowserID } from 'src/app/store/actions/user-info.actions';
import { IAppState } from 'src/app/store/states/app.state';

import { Variant } from '../models/variant';

@Injectable()
export class StorageService {

  private readonly storageIDKey = '_IK_storage_';

  private readonly variantKey = '_LG_variant_';

  private readonly timerKey = '_LG_TIME';

  constructor(
    private store: Store<IAppState>,
    // private lz: LZStringService,
    ) {
    }

  public get isExist(): boolean {
    return !!localStorage.getItem(this.storageIDKey);
  }

  public userIdExist(): boolean {
    return this.isExist;
  }

  public getUserId(): string {
    if (this.isExist) {
      const user: {userId: string} = JSON.parse(localStorage.getItem(this.storageIDKey)!);
      if (user.userId) {
        this.store.dispatch(new GetUserInfoBrowserID(user.userId));
        return user.userId;
      }
    }
    const userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const user: any = JSON.parse(localStorage.getItem(this.storageIDKey)!);
    const refreshToken = user ? user.refreshToken : '';
    const email =  user ? user.email : '';
    localStorage.setItem(this.storageIDKey, JSON.stringify({ userId, refreshToken, email }));
    this.store.dispatch(new GetUserInfoBrowserID(userId));
    return userId;
  }

  public setRefreshTokenAndEmail(refreshToken: string | null, email: string | null): void {
    let userId = '';
    if (this.isExist) {
      const user: {userId: string} = JSON.parse(localStorage.getItem(this.storageIDKey)!);
      userId = user.userId;
    }
    localStorage.setItem(this.storageIDKey, JSON.stringify({ userId, refreshToken, email }));
  }

  public setVariantName(variant: string): void {
    if (this.isExist) {
      const user: any = JSON.parse(localStorage.getItem(this.storageIDKey)!);
      user.variant = variant;
      localStorage.setItem(this.storageIDKey, JSON.stringify(user));
      return;
    }
    const userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const user: any = {};
    const refreshToken = user ? user.refreshToken : '';
    const email =  user ? user.email : '';
    user.variant = variant;
    user.userId = userId;
    user.refreshToken = refreshToken;
    user.email = email;
    localStorage.setItem(this.storageIDKey, JSON.stringify(user));
    this.store.dispatch(new GetUserInfoBrowserID(userId));
  }

  public getRefreshToken(): {refreshToken: string, email: string} | null {
    const token: {refreshToken: string, email: string} = JSON.parse(localStorage.getItem(this.storageIDKey)!);
    return token;
  }

  public setVariant(variant: Variant): void {
    const compressed = JSLZString.compress(JSON.stringify(variant));
    localStorage.setItem(this.variantKey, compressed);
    this.setTime();
  }

  public getVariant(): Variant | null {
    if (!this.checkTime()) return null;
    try {
      const info: any = JSON.parse(localStorage.getItem(this.variantKey)!);
      if (info) {
        return info;
      }
      return null;
    } catch {
      const decompressed: any = JSON.parse(JSLZString.decompress(localStorage.getItem(this.variantKey)!));
      if (decompressed) {
        return decompressed;
      }
      return null;
    }
  }

  private checkTime(): boolean {
    const storageDate: Date = new Date(JSON.parse(localStorage.getItem(this.timerKey)!));
    if (!storageDate) return false;
    const date = new Date();
    return (date < storageDate);
  }

  private setTime(): void {
    const date = new Date();
    date.setDate(date.getDate() + 7); // + 7 дней
    localStorage.setItem(this.timerKey, JSON.stringify(date));
  }
}
