import { SubscriptionStatusesEnum } from '../enums/subscription-statuses.enum';

export class ActiveSubscription {
  constructor(
    public productId: string,
    public applications: string[],
    public expiredDate: Date | null,
    public canceledDate: Date | null,
    public trial: boolean | null,
    public startDate: Date | null,
    public nextServiceFee: Date | null,
    public status: SubscriptionStatusesEnum,
    public expiredAtUnixSec: number,
    public lastPaymentAtUnixSec: number | null,
    public resources: {name: string, link: string}[] | null,
    public paymentPlan: {
      periodAmount: number,
      periodDuration: 'MONTH' | string,
      trialPeriodAmount: number ,
      trialPeriodDuration: 'MONTH' | 'WEEK' | string,
    } | null,
    public subscriptionId: string,
    public productName: string,
  ) {}
}
