import { AdditionalPack } from './../models/additional-pack';

export const ADDITINAL_PACKAGES: AdditionalPack[] = [
  new AdditionalPack('ea3fa674-0f6b-4eae-9e03-ea937bd2ae3f', 'dino_pack_title'),
  new AdditionalPack('28b8b4a7-7980-4a72-9097-2c21a845a1d3', 'math_pack_title'),
  new AdditionalPack('5b2da3ff-5d3e-453d-bdda-0364a7d43779', 'speech_pack_title'),
  new AdditionalPack('02da5f00-884f-407b-bcc6-2cb023b1493c', 'course_pack_counting10_title'),
  new AdditionalPack('55635be2-aaac-45c1-bcb5-f9973c6919a9', 'worksheets_pack_title'),
  new AdditionalPack('98e847d3-69a4-4c06-84ab-0592a36170b5', 'ol_pack_title'),
  new AdditionalPack('b6ed0e82-ab96-4901-9294-ed0b26d61a7b', 'Christmas Pack'),
  new AdditionalPack('f7e2ab0f-9cf2-4676-bc54-2a0b446f16a8', 'winter_pack'),
  new AdditionalPack('71a9e0ad-add7-4b91-9293-540f24807125', 'art_pack'),
  new AdditionalPack('f6d1e378-c135-422a-bdc0-44fa75960a4c', 'halloween_pack_title'),
  new AdditionalPack('f341b5fb-774d-4b60-8938-4684e3e7cb68', 'gift_pack'),
  new AdditionalPack('7b78aafb-9e9f-49e9-8b0f-49fbf20d712c', 'learning_pack_title'),
  new AdditionalPack('00e23c06-4006-44a9-b95d-b5f39e3c5fe3', 'creative_pack_title'),
];
