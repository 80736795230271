import { Injectable } from '@angular/core';

import { DeepLinkJson } from './../json/deeplink.json-interface';
import { DeepLink } from './deeplink';

@Injectable()
export class DeeplinkModelsFactory {
  public getDeeplink(json: DeepLinkJson): DeepLink {
    return new DeepLink(json.app_name, json.link);
  }

  public getDeepLinks(json: DeepLinkJson[]): DeepLink[] {
    return json.map(item => this.getDeeplink(item));
  }
}
