import { Action } from '@ngrx/store';

import { PaymentLink } from './../../common/models/payment-link';

export enum EPaymentLinkActions {
  AddCardLink = '[PaymentLink] Add Card Link',
  AddCardLinkSuccess = '[PaymentLink] Add Card Link Success',
  AddPaypalLink = '[PaymentLink] Add Paypal Link',
  AddPaypalLinkSuccess = '[PaymentLink] Add Paypal Link Success',
  ClearPaymentLink = '[PaymentLink] Clear Payment Link',
  ClearPaymentLinkSuccess = '[PaymentLink] Clear Payment Link Success',
}

export class AddCardLink implements Action {
  public readonly type = EPaymentLinkActions.AddCardLink;
  constructor(public payload: PaymentLink) {}
}

export class AddCardLinkSuccess implements Action {
  public readonly type = EPaymentLinkActions.AddCardLinkSuccess;
  constructor(public payload: PaymentLink) {}
}

export class AddPaypalLink implements Action {
  public readonly type = EPaymentLinkActions.AddPaypalLink;
  constructor(public payload: PaymentLink) {}
}

export class AddPaypalLinkSuccess implements Action {
  public readonly type = EPaymentLinkActions.AddPaypalLinkSuccess;
  constructor(public payload: PaymentLink) {}
}

export class ClearPaymentLinkSuccess implements Action {
  public readonly type = EPaymentLinkActions.ClearPaymentLinkSuccess;
}

export class ClearPaymentLink implements Action {
  public readonly type = EPaymentLinkActions.ClearPaymentLink;
}

export type PaymentLinkActions =
AddCardLink |
ClearPaymentLink |
AddCardLinkSuccess |
ClearPaymentLinkSuccess |
AddPaypalLink |
AddPaypalLinkSuccess;
