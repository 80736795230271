import { ActiveSubscription } from 'src/app/common/models/active-subscription';

import { LifeTimeProductStatusesEnum } from '../../common/enums/life-time-product-statuses.enum';

export interface ISubscriptionState {
  subscriptions: ActiveSubscription[] | null;
  lifeTimeProductStatus: LifeTimeProductStatusesEnum | null;
  orderIdCard: string | null;
  orderIdPaypal: string | null;
  orderId: string | null;
  errorOrderId: string | null;
  isAdditionalDiscount: boolean | null;
  oxfordWasSent: boolean;
  e4kWasSent: boolean;
  spWasSent: boolean;
  robocatWasSent: boolean;
  selectedPaymentMethod: string;
  orderMethod: string | null;
}

export const initialISubscriptionState: ISubscriptionState = {
  subscriptions: null,
  lifeTimeProductStatus: LifeTimeProductStatusesEnum.NOT_BOUGHT,
  orderIdCard: null,
  orderIdPaypal: null,
  orderId: null,
  errorOrderId: null,
  isAdditionalDiscount: false,
  oxfordWasSent: false,
  e4kWasSent: false,
  spWasSent: false,
  robocatWasSent: false,
  selectedPaymentMethod: 'card',
  orderMethod: null,
};
