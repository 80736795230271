import { PaymentPlanJson } from './../json/payment-plan.json-interface';
export class Product {
  constructor(
    public id: string,
    public priceCents: number,
    public projects: string[],
    public periodInWeeks: number,
    public currency: string,
    public oldPrice: number | null,
    public trial: string,
    public trialPriceCents: number | null,
    public trialDays: number,
    public name: string | null,
    public periodInDays: number,
    public paymentPlan: PaymentPlanJson,
    public country: string,
    public publicDescription: string,
  ) {}
}
