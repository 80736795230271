import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomCurrencyPipe } from './custom-currency.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    CustomCurrencyPipe,
  ],
  providers: [
    CustomCurrencyPipe,
    CurrencyPipe,
  ],
  exports: [
    CustomCurrencyPipe,
  ],
})
export class CustomCurrencyModule { }
