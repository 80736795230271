import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  private geo = new BehaviorSubject('USA');

  public getGeo(): Observable<string> {
    return this.geo.asObservable();
  }

  public setGeo(geo: string): void {
    this.geo.next(geo);
  }
}
