import { Action } from '@ngrx/store';

import { Variant } from './../../common/models/variant';

export enum EVariantActions {
  GetVariantByBrowserId = '[Variant] Get Variant By BrowserID',
  UpdateAndChangeVariant = '[Variant] Update and change',
  GetSpecialVariantFromServer = '[Variant] Get Special Variant',
  GetSpecialVariantFromServerAndChanged = '[Variant] Get Special Variant And Changed',
  GetVariantByType = '[Variant] Get Variant By Type',
  UpdateVariant = '[Variant] Update Variant',
}

export class GetVariantByBrowserId implements Action {
  public readonly type = EVariantActions.GetVariantByBrowserId;
  constructor(public payload: string) {}
}

export class GetVariantByType implements Action {
  public readonly type = EVariantActions.GetVariantByType;
  constructor(public browserId: string, public typeOfAbTest: string) {}
}

export class GetSpecialVariantFromServer implements Action {
  public readonly type = EVariantActions.GetSpecialVariantFromServer;
  constructor(public payload: string) {}
}

export class GetSpecialVariantFromServerAndChanged implements Action {
  public readonly type = EVariantActions.GetSpecialVariantFromServerAndChanged;
  constructor(public payload: string) {}
}

export class UpdateAndChangeVariant implements Action {
  public readonly type = EVariantActions.UpdateAndChangeVariant;
  constructor(public payload: Variant | null) {}
}

export class UpdateVariant implements Action {
  public readonly type = EVariantActions.UpdateVariant;
  constructor(public payload: Variant | null) {}
}

export type VariantActions =
GetVariantByBrowserId |
UpdateAndChangeVariant |
UpdateVariant |
GetSpecialVariantFromServerAndChanged;
