import { assocEnumToArray } from 'src/app/ui/assoc-enum-to-array.function';

export enum SubscriptionStatusesEnum {
  ACTIVE = 'active',
  APPROVED = 'approved',
  CANCEL_REFUND = 'cancel_refund',
  CANCEL_CHARGEBACK = 'cancel_chargeback',
  CANCEL_EXPIRED = 'cancel_expired',
  CANCELLED = 'cancelled',
  WAITING_FOR_CANCELING = 'waiting_for_canceling',
}

export const SUBSSCRIPTION_STATUSES_LIST = assocEnumToArray<SubscriptionStatusesEnum>(SubscriptionStatusesEnum);
