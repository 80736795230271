export enum PaymentTypeEnum {
  SELECT = 'SELECT',
  SELECT_LONG = 'SELECT_LONG',
  SELECT_TIMER_UP = 'SELECT_TIMER_UP',
  SELECT_BM = 'SELECT_BM',
  SELECT_E4K = 'SELECT_E4K',
  SELECT_SOCIAL = 'SELECT_SOCIAL',
  SELECT_CHECKBOX = 'SELECT_CHECKBOX',
  SELECT_DOUBLE_OFFER = 'SELECT_DOUBLE_OFFER',
  SELECT_DOUBLE_OFFER2 = 'SELECT_DOUBLE_OFFER2',
  SELECT_DOUBLE_EASY = 'SELECT_DOUBLE_EASY',
  SELECT_DOUBLE_BLANK = 'SELECT_DOUBLE_BLANK',
  SELECT_DOUBLE_SOCIAL = 'SELECT_DOUBLE_SOCIAL',
  SELECT_DOUBLE_LARGE = 'SELECT_DOUBLE_LARGE',
  SELECT_DOUBLE_SKILLS = 'SELECT_DOUBLE_SKILLS',
  RADIO_BLANK = 'RADIO_BLANK',
  SWITCHER = 'SWITCHER',
  TRIAL_PAY_WAS_SELECTED = 'TRIAL_PAY_WAS_SELECTED',
  BOOM = 'BOOM',
  HELLOWEEN = 'HELLOWEEN',
  UNIVERSAL_CRM = 'UNIVERSAL_CRM',
  CHRISTMAS_OFFER = 'CHRISTMAS_OFFER',
  SELECT_SHORT = 'SELECT_SHORT',
  SELECT_SMALL= 'SELECT_SMALL',
  SHORT_SCREEN = 'SHORT_SCREEN',
  LG = 'LG',
  CLOUD = 'CLOUD',
  YEAR = 'YEAR',
  WORKSHEETS = 'WORKSHEETS',
  AGAIN = 'AGAIN',
  PAYMENT_CONSTRUCTOR = 'PAYMENT_CONSTRUCTOR'
}
