import { OnBoarding } from './onboarding';
import { PaymentView } from './payment-view';

export class Variant {
  constructor(
    public id: string,
    public name: string,
    public onboarding: OnBoarding,
    // tslint:disable-next-line: variable-name
    public payment_screen: PaymentView,
    public abtest: string | null,
    public ab_test_start_event: string | null,
    public parameters: any = null,
  ) {}
}
