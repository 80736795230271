import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./route-modules/landing/route.landing.routing.module').then(m => m.RouteLandingRoutingModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./route-modules/terms/route-terms.routing.module').then(m => m.RouteTermsRoutingModule),
  },
  {
    path: 'refund-policy',
    loadChildren: () => import('./route-modules/refund/route-refund.routing.module').then(m => m.RouteRefundRoutingModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./route-modules/privacy/route-privacy.routing.module').then(m => m.RoutePrivacyRoutingModule),
  },
  {
    path: 'subscription-policy',
    loadChildren: () => import('./route-modules/subscription-policy/route-subscription-policy.routing.module').then(m => m.RouteSubscriptionPolicyRoutingModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./route-modules/help/route-help.routing.module').then(m => m.RouteHelpRoutingModule),
  },
  {
    path: 'pay',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: 'pay/:fbclid',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: 'worksheets',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: 'download',
    loadChildren: () => import('./route-modules/download/route.download.routing.module').then(m => m.RouteDownloadRoutingModule),
  },
  {
    path: 'toappstorelg',
    loadChildren: () => import('./route-modules/toappstorelg/toappstorelg.routing.module').then(m => m.ToappstorelgRoutingModule),
  },
  {
    path: ':param1',
    loadChildren: () => import('./route-modules/landing/route.landing.routing.module').then(m => m.RouteLandingRoutingModule),
  },
  {
    path: ':param1/pay',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: ':param1/:param2',
    loadChildren: () => import('./route-modules/landing/route.landing.routing.module').then(m => m.RouteLandingRoutingModule),
  },
  {
    path: ':param1/:param2/pay',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: ':param1/:param2/:param3',
    loadChildren: () => import('./route-modules/landing/route.landing.routing.module').then(m => m.RouteLandingRoutingModule),
  },
  {
    path: ':param1/:param2/:param3/pay',
    loadChildren: () => import('./route-modules/pay/route.pay.routing.module').then(m => m.RoutePayRoutingModule),
  },
  {
    path: '*',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), TranslateModule],
  exports: [RouterModule, TranslatePipe],
  providers: [TranslateModule],
})
export class AppRoutingModule { }
