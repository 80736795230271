export enum AdditionPurchaseTypeEnum {
  WORKSHEETS_SPECIAL = 'WORKSHEETS_SPECIAL',
  WORKSHEETS_SEPARATE = 'WORKSHEETS_SEPARATE',
  WORKSHEETS_2_SCREENS = 'WORKSHEETS_2_SCREENS',
  WORKSHEETS_REVIEWS = 'WORKSHEETS_REVIEWS',
  WORKSHEETS_BM = 'WORKSHEETS_BM',
  WORKSHEETS_NOOM = 'WORKSHEETS_NOOM',
  WORKSHEETS_NOOM_SPECIAL = 'WORKSHEETS_NOOM_SPECIAL',
  OXFORD_PAY = 'OXFORD_PAY',
  OXFORD_NOOM = 'OXFORD_NOOM',
  CHRISTMAS_WORKSHEETS = 'CHRISTMAS_WORKSHEETS',
  E4K = 'E4K',
  E4K_SECOND = 'E4K_SECOND',
  E4K2 = 'E4K2',
  SP = 'SP',
  ROBOCAT = 'ROBOCAT',
}
