import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface ZendeskBody {
  subject: string;
  comment: { body: string };
  requester: {
    name: string,
    email: string,
  };
  priority: string;
}

@Injectable()
export class ZendeskService {

  public subdomain = 'intellectokids';

  public email = 'mk@intellectokids.com';

  public token = 'ukYUuFeluAWUwb6AKAPIwuNYeADGS6LGWepShYxK';

  public token2 = 'cafbfb3f2c5784d919ee9bc4704e4872073cbfdcecdaba104c6094e5480c3443';

  public headers: any = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: '',
  };

  constructor(
    private httpClient: HttpClient,
  ) {
    const str = this.email + '/token:' + this.token;
    this.headers.Authorization = `Basic ${btoa(str)}`;
  }

  public sendTicket(name: string, email: string, body: string): Observable<any> {
    const ticket: ZendeskBody = {
      subject: 'Support form request (tilda website)',
      comment: { body },
      requester: {
        name,
        email,
      },
      priority: 'normal',
    };
    const url = `https://${this.subdomain}.zendesk.com/api/v2/tickets.json`;
    return this.httpClient
      .post(`${url}`, { ticket }, { headers: this.headers })
      .pipe(map(data => data));
  }
}
