import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_URL_GATEWAY } from './../../api-service.config';

// Our service to handle errors (ideally in its own file)
@Injectable()
export class ErrorLogService {

  private readonly storageIDKey = '_IK_storage_';

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private readonly api: string,
    private readonly router: Router,
  ) {
  }

  public async logError(error: Error, level: string): Promise<void> {
    // tslint:disable-next-line: no-console
    console.error(error);
    const indexOfURLSegment = error.message.indexOf("Error: Cannot match any routes. URL Segment: '");
    if (indexOfURLSegment !== -1) {
      const indexOfQuotes =  error.message.indexOf("'");
      const indexOfSlash = error.message.indexOf('/');

      if (indexOfSlash !== -1 && indexOfSlash > indexOfQuotes) {
        const path = error.message.substr(indexOfQuotes + 1, indexOfSlash - indexOfQuotes - 1);
        this.router.navigate([`/${ path }`]);
      } else {
        this.router.navigate(['']);
      }
    }
    if (error instanceof HttpErrorResponse) {
      this.errorHappened(error.message, `${ error.status } ${ error.statusText }`, level).subscribe();
      return;
    }

    this.errorHappened(error.message, error.stack || error.name || '', level).subscribe();
  }

  public errorHappened(message: string, error: string, level: string): Observable<string> {
    const user: {
      userId: string,
      email: string,
      variant: string,
    } = JSON.parse(localStorage.getItem(this.storageIDKey)!);
    const body: any = {
      message,
      error,
      level,
      parameters: {
        userAgent: navigator.userAgent,
        browserId: user.userId,
        email: user.email ? user.email : '',
        variant: user.variant ? user.variant : '',
        link: window.location.href,
      },
    };

    return this.httpClient
      .post<string>(`${ this.api }/subscriptions/frontend_error`, body)
      .pipe(map(response => response));
  }
}

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  private errorList = new Set();

  constructor(
    private errorLogService: ErrorLogService,
  ) {
    super();
  }

  public handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    let level = 'unknown';

    if (
      error.message.indexOf('subscriptions/get_available_products') !== -1 ||
      error.message.indexOf('subscriptions/create_purchase_payment_intent') !== -1 ||
      error.message.indexOf('subscriptions/create_pp_subscription') !== -1 ||
      error.message.indexOf('subscriptions/create_pp_purchase') !== -1 ||
      error.message.indexOf('subscriptions/recurring_payment') !== -1 ||
      error.message.indexOf('subscriptions/create_payment_intent') !== -1 ||
      error.message.indexOf('api/gateway/website-translations') !== -1
    ) {
      level = 'critical';
    }

    if (chunkFailedMessage.test(error.message)) {
      // window.location.reload();
      return;
    }
    if (this.errorList.has(error.message)) {
      return;
    }
    this.errorList.add(error.message);
    this.errorLogService.logError(error, level);
  }
}
