import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { map, Observable } from "rxjs";
import { API_URL_GATEWAY } from "src/app/api-service.config";

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    this.nts.notTranslated(params.key);
    return params.key;
  }

  constructor(private nts: NotTranslatedService) {}
}

@Injectable({
  providedIn: 'root'
})
export class NotTranslatedService {

  private keyList = new Set();

  private readonly storageIDKey = '_IK_storage_';

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private readonly api: string,
  ) {}
  public notTranslated(key: string): void {
     if (this.keyList.has(key)) {
      return;
    }
    this.keyList.add(key);
    if (key.indexOf('_') !== -1) {
      this.errorHappened(`Not translated ${ key }`, 'Not translated', 'low').subscribe()
    }
  }

  private errorHappened(message: string, error: string, level: string): Observable<string> {
    const user: {
      userId: string,
      email: string,
      variant: string,
    } = JSON.parse(localStorage.getItem(this.storageIDKey)!);
    const body: any = {
      message,
      error,
      level,
      parameters: {
        userAgent: navigator.userAgent,
        browserId: user.userId,
        email: user.email ? user.email : '',
        variant: user.variant ? user.variant : '',
        link: window.location.href,
      },
    };

    return this.httpClient
      .post<string>(`${ this.api }/subscriptions/frontend_error`, body)
      .pipe(map(response => response));
  }
}