import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { IAppState } from '../states/app.state';

import { paymentLinkReducers } from './payment-link.reducers';
import { productReducers } from './product.reducers';
import { quizReducers } from './quiz.reducers';
import { subscriptionReducers } from './subscription.reducers';
import { userInfoReducers } from './user-info.reducers';
import { variantReducers } from './variant.reducers';

export const appReducers: ActionReducerMap<IAppState, any> = {
  router: routerReducer,
  subscription: subscriptionReducers,
  userInfo: userInfoReducers,
  product: productReducers,
  paymentLink: paymentLinkReducers,
  variant: variantReducers,
  quiz: quizReducers,
};
