import { Action } from '@ngrx/store';

export enum EQuizActions {
  UpdateQuiz = '[Quiz] Update Quiz',
  UpdateQuizAnswer = '[Quiz] Update Quiz Answer',
}

export class UpdateQuiz implements Action {
  public readonly type = EQuizActions.UpdateQuiz;
  constructor(
    public subject: string,
    public total: number,
    public result: number,
  ) {}
}

export class UpdateQuizAnswer implements Action {
  public readonly type = EQuizActions.UpdateQuizAnswer;
  constructor(
    public subject: string,
    public answer: string | string[],
  ) {}
}

export type QuizActions = UpdateQuiz | UpdateQuizAnswer;
