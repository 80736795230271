export interface IQuizState {
  subjects: {
    subject: string,
    result: number,
    total: number,
  }[];
  answers: {
    subject: string,
    answer: string | string[],
  }[];
}

export const initialQuizState: IQuizState = {
  subjects: [],
  answers: [],
};
