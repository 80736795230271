import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Product } from 'src/app/common/models/product';
import { PaymaxisService } from 'src/app/common/services/paymaxis.service';
import { SolidService } from 'src/app/common/services/solid.service';
import { selectAllData } from 'src/app/store/selectors/app.selector';
import { IAppState } from 'src/app/store/states/app.state';

@Injectable()
export class LifeTimeService {
  private paymaxis = false;

  constructor(
    private readonly paymaxisService: PaymaxisService,
    private readonly solidService: SolidService,
    private readonly store: Store<IAppState>,
  ) {
    this.getPaymaxis();
  }

  public buyLifeTimeProducts(products: Product[], cardTokenOrderId: string, oneClickPayment = false): Observable<{ products: Product[], orderIds: string[] }> {
    return this.paymaxis ? this.paymaxisService.buyLifeTimeProducts(products, cardTokenOrderId, oneClickPayment) : this.solidService.buyLifeTimeProducts(products, cardTokenOrderId, oneClickPayment);
  }

  private getPaymaxis(): void {
    this.store.pipe(select(selectAllData))
      .pipe(first())
      .subscribe(data => this.paymaxis = !!data!.variant.variant?.payment_screen.parameters.paymaxis);
  }
}
