import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/common/services/storage.service';
import { selectAllData } from 'src/app/store/selectors/app.selector';
import { IAppState } from 'src/app/store/states/app.state';

import { AnalyticTypesEnum } from './../enums/analytic-types.enum';
import { CheckoutStatus } from './../models/checkout-status';

@Injectable()
export class AnalyticService {

  private allDataObservable = this.store.pipe(select(selectAllData));

  private allData!: IAppState;

  private os!: string;

  private checkoutStatus = new CheckoutStatus('', false, false, false);

  constructor(
    private httpClient: HttpClient,
    private store: Store<IAppState>,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
  ) {
    this.subscriptionStore();
    this.os = this.getOS();
  }

  public analyticWebPoint(
    name: AnalyticTypesEnum,
    properties: any,
    ): Observable<string> {
    // tslint:disable-next-line: no-console
    console.log(name);
    const params = this.activatedRoute.snapshot.queryParamMap;
    properties.Email = this.allData.userInfo.email || '';
    properties.userAgent = navigator.userAgent;
    properties.os = this.os;
    properties.Browser_ID = this.storageService.getUserId();
    properties.traffic_source = this.getTrafficSource();
    properties.promoter = this.allData.userInfo.promoter || '';
    properties.referrer = document.referrer;
    properties.link = window.location.href;
    properties.ABTest = this.allData.variant.variant ? this.allData.variant.variant.abtest : '';
    properties.Variant = this.allData.variant.variant ? this.allData.variant.variant.name : '';
    properties.ab_test_start_event = this.allData.variant.variant ? this.allData.variant.variant.ab_test_start_event : '';
    properties.ab_test_start_name = this.allData.variant.variant && this.allData.variant.variant.parameters ? this.allData.variant.variant.parameters.ab_test_start_name : '';
    properties.campaign_id = params.get('campaign_id');
    properties.adset_id = params.get('adset_id');
    properties.ad_id = params.get('ad_id');
    properties.placement = params.get('utm_medium');
    properties.site_source_name = params.get('utm_source');
    properties.utm_medium = params.get('utm_medium');
    properties.utm_source = params.get('utm_source');
    properties.utm_campaign = params.get('utm_campaign');
    properties.utm_crm_type = params.get('utm_crm_type');
    properties.utm_content = params.get('utm_content');
    properties.utm_term = params.get('utm_term');
    properties.utm_crm_email = params.get('utm_crm_email');
    properties.inner_height = window.innerHeight.toString();
    properties.inner_width = window.innerWidth.toString();
    properties.onboarding = this.allData.variant.variant ? this.allData.variant.variant.onboarding.name : '';
    properties.payment_screen = this.allData.variant.variant ? this.allData.variant.variant.payment_screen.name : '';
    properties.subscription_id = this.allData.subscription.subscriptions && this.allData.subscription.subscriptions.length ? this.allData.subscription.subscriptions[0].subscriptionId : '';
    properties.child_age = this.allData!.userInfo.childAge || '';
    properties.failed_reason = properties.failed_reason ? properties.failed_reason.substr(0, 1000) : '';
    properties.browser_lang = this.translate.getBrowserLang() ? this.translate.getBrowserLang() : '';
    properties.used_lang = this.translate.currentLang;

    if (this.isEventNameAfterChoosingOffer(name)) {
      properties.product_name = this.allData.userInfo.product ? this.allData.userInfo.product.name : '';
    }

    if (this.isEventForCheckoutReady(name)) {
      this.changeCheckoutStatus(name, this.allData.userInfo.product!.name!);
    }

    if (this.isEventForOrderId(name)) {
      properties.order_id = this.allData!.subscription.orderId || '';
    }

    if (name === AnalyticTypesEnum.WebPayCheckoutClosed) {
      this.checkoutStatus.opened = false;
    }

    Object.keys(properties).forEach(key => {
      if (typeof(properties[key]) !== 'string') {
        try {
          properties[key] = properties[key].toString();
        } catch {
          properties[key] = '';
        }
      }
    });

    const body = {
      events: [{
        name,
        type: 'web',
        source: 'PaymentSite',
        uid: this.allData.userInfo.auth ? this.getUserIdFromToken(this.allData.userInfo.auth.id_token) : '',
        time: moment().subtract(new Date().getTimezoneOffset(), 'minutes').toDate().getTime(),
        event_properties: properties,
      }],
    };

    return this.httpClient
      .post<{ status: string }>('/api/event', body)
      .pipe(map(response => response.status));
  }

  public addUTMParamsIntoCookie(): void {
    const map = this.activatedRoute.snapshot.queryParamMap;
    if (this.getCookie('campaign_id')) {
      return;
    }

    document.cookie = `campaign_id=${ map.get('campaign_id') || '' }`;
    document.cookie = `adset_id=${ map.get('adset_id') || '' }`;
    document.cookie = `ad_id=${ map.get('ad_id') || '' }`;
    document.cookie = `placement=${ map.get('utm_medium') || '' }`;
    document.cookie = `utm_source=${ map.get('utm_source') || '' }`;
  }

  private getCookie(name: string): string {
    const matches = document.cookie.match(new RegExp(`(?:^|; )${ name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') }=([^;]*)`));
    return matches ? decodeURIComponent(matches[1]) : '';
  }

  private getTrafficSource(): string {
    if (this.allData.userInfo.source) {
      return this.allData.userInfo.source;
    }

    if (!this.allData.variant.variant) {
      return '';
    }

    if (
      this.allData.variant.variant.name.toLowerCase().indexOf('email') !== -1 ||
      this.allData.variant.variant.name.toLowerCase().indexOf('crm') !== -1
      ) {
      return 'email';
    }

    if (window.location.pathname.indexOf('basic') !== -1) {
      return 'web';
    }

    return 'campaign';
  }

  private getUserIdFromToken(tokenOld: string): string {
    const token = `${ tokenOld }`;
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('').map(c => {
        return `%${ (`00 ${ c.charCodeAt(0).toString(16) }`).slice(-2) }`;
    }).join(''));

    const obj = JSON.parse(base64);
    return obj.sub ? obj.sub : '';
  }

  private subscriptionStore(): void {
    this.allDataObservable
      .subscribe(data => this.allData = data!);
  }

  private isEventNameAfterChoosingOffer(name: AnalyticTypesEnum): boolean {
    switch (name) {
      case AnalyticTypesEnum.WebPayOfferValidated :
      case AnalyticTypesEnum.WebPayOfferValidationFailed :
      case AnalyticTypesEnum.WebPayAdditionalOfferClicked :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidated :
      case AnalyticTypesEnum.WebPaySubscribeButtonClicked :
      case AnalyticTypesEnum.WebPaySubscribeButtonClickedLoaded :
      case AnalyticTypesEnum.WebPayCrossgradeAccepted :
      case AnalyticTypesEnum.WebPayCrossgradeDeclined :
      case AnalyticTypesEnum.WebPayStoreButtonVisible :
      case AnalyticTypesEnum.WebPayStoreDownloadButtonClicked :
      case AnalyticTypesEnum.WebPayOxfordOfferView :
      case AnalyticTypesEnum.WebPayOxfordShowAgain :
      case AnalyticTypesEnum.WebPayOxfordOfferSubscribeButtonClicked :
      case AnalyticTypesEnum.WebPayOxfordOfferSubscribed :
      case AnalyticTypesEnum.WebCardReady :
      case AnalyticTypesEnum.WebPaypalReady :
      case AnalyticTypesEnum.WebPayOxfordOfferSubscribedFailed :
      case AnalyticTypesEnum.WebPayAdditionalOfferClicked :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidated :
      case AnalyticTypesEnum.WebSpecoffer302BuyClick :
      case AnalyticTypesEnum.WebSpecoffer302Subscribed :
      case AnalyticTypesEnum.WebSpecoffer302SubscribedFailed :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidated_SpecialBack :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidated_Special302 :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidationFailed :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidationFailed_Special302 :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidationFailed_SpecialBack :
      case AnalyticTypesEnum.WebPayCheckoutClick :
      case AnalyticTypesEnum.WebPayCheckoutError :
      case AnalyticTypesEnum.WebPayCheckoutReady :
      case AnalyticTypesEnum.WebPayCheckoutView : {
        return true;
      }

      default: return false;
    }
  }

  private isEventForCheckoutReady(name: AnalyticTypesEnum): boolean {
    switch (name) {
      case AnalyticTypesEnum.WebPayCheckoutView :
      case AnalyticTypesEnum.WebCardReady :
      case AnalyticTypesEnum.WebPaypalReady : {
        return true;
      }

      default: return false;
    }
  }

  private isEventForOrderId(name: AnalyticTypesEnum): boolean {
    switch (name) {
      case AnalyticTypesEnum.WebPayOfferValidated :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidated : {
        return true;
      }

      default: return false;
    }
  }

  private changeCheckoutStatus(name: AnalyticTypesEnum, productName: string): void {
    switch (name) {
      case AnalyticTypesEnum.WebPayCheckoutView : {
        this.checkoutStatus.productName === productName ? this.checkoutStatus.opened = true : this.checkoutStatus = new CheckoutStatus(productName, true, false, false);
        setTimeout(() => this.checkCheckoutStatus(), 5000);
        break;
      }
      case AnalyticTypesEnum.WebCardReady : {
        this.checkoutStatus.productName === productName ? this.checkoutStatus.cardReady = true : this.checkoutStatus = new CheckoutStatus(productName, false, false, true);
        break;
      }
      case AnalyticTypesEnum.WebPaypalReady : {
        this.checkoutStatus.productName === productName ? this.checkoutStatus.paypalReady = true : this.checkoutStatus = new CheckoutStatus(productName, false, true, false);
        break;
      }
    }
    if (this.checkoutStatus.opened && this.checkoutStatus.paypalReady && this.checkoutStatus.cardReady) {
      this.analyticWebPoint(AnalyticTypesEnum.WebPayCheckoutReady, {}).subscribe();
    }
  }

  private checkCheckoutStatus(): void {
    if (this.checkoutStatus.opened && (!this.checkoutStatus.cardReady || !this.checkoutStatus.paypalReady) && !this.allData!.product.paypalDisabled) {
      // tslint:disable-next-line: variable-name
      const failed_reason = `${ !this.checkoutStatus.cardReady ? 'card is not ready' : ''} ${ !this.checkoutStatus.paypalReady ? 'paypal is not ready' : ''}`;
      this.analyticWebPoint(AnalyticTypesEnum.WebPayCheckoutError, { failed_reason }).subscribe();
    }
  }

  private getOS(): string {
    const userAgent = navigator.userAgent;
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    const isMobile = toMatch.some(toMatchItem => {
      return userAgent.match(toMatchItem);
    });

    if (!isMobile) {
      return 'Web';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'Other mobile';
  }
}
