export enum AdditionTypeEnum {
  SEVERAL_OFFERS = 'SEVERAL_OFFERS',
  ADDITIONAL_PAYMENT_TIMER_UP = 'ADDITIONAL_PAYMENT_TIMER_UP',
  ADDITIONAL_LONG = 'ADDITIONAL_LONG',
  ADDITION_SELECT_SHORT = 'ADDITION_SELECT_SHORT',
  ADDITION_CHRISTMAS = 'ADDITION_CHRISTMAS',
  BLACK_FRIDAY = 'BLACK_FRIDAY',
  ADDITION_BM = 'ADDITION_BM',
  ADDITION_CHECKBOX = 'ADDITION_CHECKBOX',
  ADDITION_SWITCHER = 'ADDITION_SWITCHER',
  ADDITION_DOUBLE_OFFER = 'ADDITION_DOUBLE_OFFER',
  ADDITION_DOUBLE_LARGE = 'ADDITION_DOUBLE_LARGE',
  ADDITION_DOUBLE_SKILLS = 'ADDITION_DOUBLE_SKILLS',
  ADDITION_DOUBLE_LARGE_POPUP = 'ADDITION_DOUBLE_LARGE_POPUP',
  ADDITION_CONSTRUCTOR = 'ADDITION_CONSTRUCTOR'
}
