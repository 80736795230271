import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ActiveSubscription } from 'src/app/common/models/active-subscription';

import { SubscriptionService } from '../../common/services/subscription.service';
import { ESubscriptionActions, GetSubscriptionsSuccess, UpdateSubscriptions } from '../actions/subscription.actions';

import { GetSubscriptionsFromServer } from './../actions/subscription.actions';

@Injectable()
export class SubscriptionEffects {
  public getSubscriptionsFromBack = createEffect(() => {
    return this.actions.pipe(
      ofType<GetSubscriptionsFromServer>(ESubscriptionActions.GetSubscriptionsFromServer),
      switchMap(() => this.subscriptionService.getAllSubscriptions()),
      switchMap((subscriptions: ActiveSubscription[]) => {
        return of(new GetSubscriptionsSuccess(subscriptions));
      }),
    );
  });

  public updateSubscriptions = createEffect(() => {
    return this.actions.pipe(
      ofType<UpdateSubscriptions>(ESubscriptionActions.UpdateSubscriptions),
      map(params => params.payload),
      switchMap((subscriptions: ActiveSubscription[]) => {
        return of(new GetSubscriptionsSuccess(subscriptions));
      }),
    );
  });

  constructor(
    private subscriptionService: SubscriptionService,
    private actions: Actions,
  ) {}
}
