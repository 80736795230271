import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { API_URL_GATEWAY } from 'src/app/api-service.config';
import { Product } from 'src/app/common/models/product';
import { StorageService } from 'src/app/common/services/storage.service';
import { SetOrderIdCard, SetOrderIdPaypal } from 'src/app/store/actions/subscription.actions';
import { selectUserInfo } from 'src/app/store/selectors/user-info.selector';
import { IAppState } from 'src/app/store/states/app.state';
import { IUserInfoState } from 'src/app/store/states/user-info.state';
import { v4 as uuidv4 } from 'uuid';

import { PaymaxisDataJson } from './../json/paymaxis-data.json.interface';
import { PaymaxisStatusJson } from './../json/paymaxis-status.json-interface';

@Injectable()
export class PaymaxisService {

  private userInfoObservable = this.store.pipe(select(selectUserInfo));

  private userInfo!: IUserInfoState;

  private cardSubject = new Subject<Product>();

  private paypalSubject = new Subject<Product>();

  private destroy = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private api: string,
    private store: Store<IAppState>,
    private storageService: StorageService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.subscriptionStore();
  }

  public getPaymentForm(): Observable<PaymaxisDataJson> {
    return this.cardSubject
    .pipe(switchMap(product => {
      return this.userInfo.purchases && this.userInfo.purchases.length ? this.getPurchase(product, 'CREDIT_CARD') : this.getSubscription(product, 'CREDIT_CARD');
    }));
  }

  public updatePaymentForm(product: Product): void {
    this.cardSubject.next(product);
  }

  public updatePaypalLink(product: Product): void {
    this.paypalSubject.next(product);
  }

  public getPaypalLink(): Observable<PaymaxisDataJson> {
    return this.paypalSubject
      .pipe(switchMap(product => {
        return this.userInfo.purchases && this.userInfo.purchases.length ? this.getPurchase(product, 'PAYPAL') : this.getSubscription(product, 'PAYPAL');
      }));
  }

  public getStatus(orderId: string): Observable<PaymaxisStatusJson> {
    return this.httpClient
      .get<{ payload: PaymaxisStatusJson }>(`${ this.api }/paymaxis/order/status`, { params: { order_id: orderId } })
      .pipe(map(answer  => {
        return answer.payload;
      }));
  }

  public buyLifeTimeProducts(products: Product[], cardTokenOrderId: string, oneClickPayment = false): Observable<{ products: Product[], orderIds: string[] }> {
    const productsIds = products.map(item => item.id);
    const body: any = {
      products_ids: productsIds,
      card_token_order_id:cardTokenOrderId,
      order_description: products[0].publicDescription,
      purchase_site: 'PaymentSite',
    };

    if (oneClickPayment) {
      body.one_click_payment = true;
    }

    return this.httpClient
      .post<{ payload: { provider_state: string, order_id: string }}>(`${this.api}/paymaxis/transaction/recurring`, body)
      .pipe(
        mergeMap(response => {
          if (!response) {
            return of({ products: [], orderIds: [] });
          }
          if (response.payload.provider_state === 'COMPLETED') {
            return of({ products, orderIds: [response.payload.order_id] });
          }

          if ((response.payload.provider_state === 'DECLINED' || response.payload.provider_state === 'CANCELLED') && products.length === 1) {
            return of({ products: [], orderIds: [] });
          }
          const observables = products.map(product => this.buyLifeTimeProduct(product, cardTokenOrderId));
          return forkJoin(observables)
            .pipe(map(answer => {
              const products: Product[] = [];
              const orderIds: string[] = [];
              answer.forEach(item => {
                if (item.status === 'COMPLETED') {
                  products.push(item.product);
                  orderIds.push(item.order_id);
                }
              });
              return { products, orderIds };
            }));
        }),
        );
  }

  public buyLifeTimeProduct(product: Product, cardTokenOrderId: string): Observable<{status: string, product: Product, order_id: string }> {
    const productsIds = [product].map(item => item.id);

    return this.httpClient
      .post<{ provider_state: string, order_id: string }>(`${this.api}/paymaxis/transaction/recurring`, {
        products_ids: productsIds,
        card_token_order_id:cardTokenOrderId,
        order_description: product.publicDescription,
        purchase_site: 'PaymentSite',
      })
      .pipe(
        map(response => {
          return {
            product,
            status: response.provider_state,
            order_id: response.order_id,
          };
        }),
        );
  }

  private getPurchase(product: Product, method: 'CREDIT_CARD' | 'PAYPAL'): Observable<PaymaxisDataJson> {
    const orderId = uuidv4();
    const body = {
      ...this.getBodyForOrderCreate(product, method),
      order_id: orderId,
      apple_pay_domain: 'welcome.intellectokids.com',
    };

    return this.httpClient
      .post<{ payload: PaymaxisDataJson }>(`${ this.api }/subscriptions/paymaxis/purchase`, body)
      .pipe(map(answer => {
        method === 'CREDIT_CARD' ? this.store.dispatch(new SetOrderIdCard(orderId)) : this.store.dispatch(new SetOrderIdPaypal(orderId));
        return answer.payload;
      }));
  }

  private getSubscription(product: Product, method: 'CREDIT_CARD' | 'PAYPAL'): Observable<PaymaxisDataJson> {

    const orderId = uuidv4();
    const body = {
      ...this.getBodyForOrderCreate(product, method),
      order_id: orderId,
      apple_pay_domain: 'welcome.intellectokids.com',
    };

    return this.httpClient
      .post<{ payload: PaymaxisDataJson }>(`${ this.api }/subscriptions/paymaxis/subscription`, body)
      .pipe(map(answer  => {
        method === 'CREDIT_CARD' ? this.store.dispatch(new SetOrderIdCard(orderId)) : this.store.dispatch(new SetOrderIdPaypal(orderId));
        return answer.payload;
      }));
  }

  private subscriptionStore(): void {
    this.userInfoObservable
      .pipe(takeUntil(this.destroy))
      .subscribe(data => this.userInfo = data!);
  }

  private getBodyForOrderCreate(product: Product, method: 'CREDIT_CARD' | 'PAYPAL'): {} {
    const params = this.activatedRoute.snapshot.queryParamMap;
    const traficSource = params.get('campaign_id');
    const utmSource = params.get('utm_source');

    let fbc = this.getFromCookie('_fbc');
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');
    if (!fbc && fbclid) {
      fbc = `fb.1.${ new Date().getTime().toString() }.${ fbclid }`;
    }

    const result: any = {
      fbc,
      traffic_source: traficSource,
      website: utmSource,
      source_url: window.location.href,
      purchase_site: 'PaymentSite',
      order_description: product.publicDescription,
      event_id: this.storageService.getUserId(),
      fbp: this.getFromCookie('_fbp'),
      user_agent: navigator.userAgent,
      payment_method: method,
      // firstname: 'John',
      // lastname: 'Ivanov',
      // phone: '77038889911',
    };

    if (this.userInfo.purchases && this.userInfo.purchases.length) {
      result.products_ids = this.userInfo.purchases.map(item => item.id);
    } else {
      result.product_id = product.id;
    }

    return result;
  }

  private getFromCookie(name: string): string | null {
    const matches = document.cookie.match(new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
  }
}
