export enum LandingTypeEnum {
  START = 'START',
  AGE_PICTURE = 'AGE_PICTURE',
  AGE_DINO = 'AGE_DINO',
  AGE_CUSTOM = 'AGE_CUSTOM',
  AGE_FLEXIBLE = 'AGE_FLEXIBLE',
  NAME = 'NAME',
  SUBJECTS = 'SUBJECTS',
  SUBJECTS_ICONS = 'SUBJECTS_ICONS',
  SUBJECTS_PICTURES = 'SUBJECTS_PICTURES',
  SUBJECTS_WITH_FREE = 'SUBJECTS_WITH_FREE',
  SUBJECTS_SAVE = 'SUBJECTS_SAVE',
  MULTISELECT = 'MULTISELECT',
  SCORE = 'SCORE',
  NUMBER_INPUT = 'NUMBER_INPUT',
  AGE_SINGLE_OPTION = 'AGE_SINGLE_OPTION',
  QUESTION = 'QUESTION',
  TWO_BUTTONS = 'TWO_BUTTONS',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  LOADING = 'LOADING',
  FULL_PHOTO = 'FULL_PHOTO',
  ADVANTAGES_AND_REVIEWS = 'ADVANTAGES_AND_REVIEWS',
  LOADING_TWO_SCREENS = 'LOADING_TWO_SCREENS',
  LOADING_THREE_SCREENS = 'LOADING_THREE_SCREENS',
  LOADING_TRIPLE_PROGRESS = 'LOADING_TRIPLE_PROGRESS',
  LOADING_QUIZ = 'LOADING_QUIZ',
  SKILLS = 'SKILLS',
  GROW = 'GROW',
  PERSONALIZED = 'PERSONALIZED',
  PICTURE_AND_BUTTON = 'PICTURE_AND_BUTTON',
  REVIEWS = 'REVIEWS',
  LANDING = 'LANDING',
  LONG_LANDING = 'LONG_LANDING',
  PHOTO_REVIEW = 'PHOTO_REVIEW',
  HURT = 'HURT',
  INTERESTS = 'INTERESTS',
  INTERESTS2 = 'INTERESTS2',
  PROFILE = 'PROFILE',
  WHAT_DO_YOU_GET = 'WHAT_DO_YOU_GET',
  QUIZ_QUESTION = 'QUIZ_QUESTION',
  QUIZ_SUBJECT_RESULTS = 'QUIZ_SUBJECT_RESULTS',
  QUIZ_GENERAL_RESULTS = 'QUIZ_GENERAL_RESULTS',
  USP = 'USP',
  CHILDREN_INSIDE = 'CHILDREN_INSIDE',
  SCHOOL_READLINES = 'SCHOOL_READLINES',
  ANIMATED_BRAIN = 'ANIMATED_BRAIN',
  LOADING_CIRCLE = 'LOADING_CIRCLE',
  PICTURE_FIRST = 'PICTURE_FIRST',
  REVIEWS_FREE = 'REVIEWS_FREE',
  CERTIFIED = 'CERTIFIED',
  GRAPH = 'GRAPH',
  GRAPH_12_WEEKS = 'GRAPH_12_WEEKS',
  WYG = 'WYG',
  TOOLTIP = 'TOOLTIP',
  LIST_ICONS = 'LIST_ICONS',
  REVIEWS_AND_RATING = 'REVIEWS_AND_RATING',
  GREEN_LIST_RED_TEXT = 'GREEN_LIST_RED_TEXT',
  CUSTOM_ANSWER = 'CUSTOM_ANSWER',
  PREPS_3_IN_1 = 'PREPS_3_IN_1',
}
