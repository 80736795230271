import { PaymentLink } from '../../common/models/payment-link';

export interface IPaymentLinkState {
  paypalLinks: PaymentLink[] | null;
  cardLinks: PaymentLink[] | null;
}

export const initialPaymentLinkState: IPaymentLinkState = {
  paypalLinks: [],
  cardLinks: [],
};
