import { Product } from './../../common/models/product';

export interface IProductState {
  products: Product[] | null;
  lifetimeProducts: Product[] | null;
  twinsSupported: boolean | null;
  paypalDisabled: boolean | null;
  isCardHolderVisible: boolean | null;
  applePurchaseOn: boolean | null;
}

export const initialProductState: IProductState = {
  products: null,
  lifetimeProducts: null,
  twinsSupported: null,
  paypalDisabled: null,
  isCardHolderVisible: null,
  applePurchaseOn: null,
};
