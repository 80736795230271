import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Variant } from 'src/app/common/models/variant';
import { ScreenService } from 'src/app/common/services/screen.service';

import { EVariantActions, GetSpecialVariantFromServer, GetSpecialVariantFromServerAndChanged, GetVariantByBrowserId, GetVariantByType, UpdateAndChangeVariant, UpdateVariant } from './../actions/variant.actions';

@Injectable()
export class VariantEffects {

  public getVariantByBrowserId = createEffect(() => {
    return this.actions.pipe(
      ofType<GetVariantByBrowserId>(EVariantActions.GetVariantByBrowserId),
      switchMap(browserId => this.screenService.getVariant(browserId.payload)),
      switchMap(variant => {
        return of(new UpdateVariant(this.addedVatToPaymentsScreens(variant)));
      }),
    );
  });

  public getVariantByType = createEffect(() => {
    return this.actions.pipe(
      ofType<GetVariantByType>(EVariantActions.GetVariantByType),
      switchMap(browserId => this.screenService.getVariantByType(browserId.browserId, browserId.typeOfAbTest)),
      switchMap(variant => {
        return of(new UpdateVariant(this.addedVatToPaymentsScreens(variant)));
      }),
    );
  });

  public getSpecialVariant = createEffect(() => {
    return this.actions.pipe(
      ofType<GetSpecialVariantFromServer>(EVariantActions.GetSpecialVariantFromServer),
      switchMap(id => this.screenService.getSpecialVariant(id.payload)),
      switchMap(variant => {
        return of(new UpdateVariant(this.addedVatToPaymentsScreens(variant)));
      }),
    );
  });

  public getSpecialVariantAndChanged = createEffect(() => {
    return this.actions.pipe(
      ofType<GetSpecialVariantFromServerAndChanged>(EVariantActions.GetSpecialVariantFromServerAndChanged),
      switchMap(id => this.screenService.getSpecialVariantAndChange(id.payload)),
      switchMap(variant => {
        return of(new UpdateAndChangeVariant(this.addedVatToPaymentsScreens(variant)));
      }),
    );
  });

  constructor(
    private actions: Actions,
    private screenService: ScreenService,
  ) {}

  private addedVatToPaymentsScreens(variant: Variant | null): Variant | null {
    if (!variant) {
      return null;
    }
    const paymentScreens = variant.payment_screen.screens ?
      variant.payment_screen.screens!.map(item => {
        return { order: item.order, info: item.info };
      }) : variant.payment_screen.parameters.prevScreens!;

    variant.payment_screen.parameters.prevScreens = paymentScreens!.map(screen => {
      if (screen.info.blocks) {
        screen.info.parameters.blocks = screen.info.blocks.map((item: any) => {
          item.block.parameters = item.block.parameters ? item.block.parameters : {};
          item.block.parameters.vats = variant.parameters.vats ? variant.parameters.vats : {};
          return item;
        });
      }
      screen.info.parameters.vats = variant.parameters.vats ? variant.parameters.vats : {};
      return screen;
    });

    variant.payment_screen.screens = variant.payment_screen.parameters.prevScreens;

    return variant;
  }
}
