import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, Observable, retry } from 'rxjs';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader  {
  private contentHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*' });

  constructor(private httpClient: HttpClient) {}
  public getTranslation(lang: string): Observable<any> {
    const apiAddress = `/api/gateway/website-translations/${ lang }.json?cb=${ (new Date()).getTime() }`;
    return this.httpClient.get(apiAddress, { headers: this.contentHeader })
          .pipe(
            retry(3),
            catchError(_ => this.httpClient.get('/assets/translation/en.json')),
          );
  }
}
