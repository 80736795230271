import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UpdateAndChangeVariant, UpdateVariant } from 'src/app/store/actions/variant.actions';
import { IAppState } from 'src/app/store/states/app.state';

import { Variant } from '../models/variant';

import { API_URL_GATEWAY } from './../../api-service.config';
import { DEFAULT_VARIANT } from './../constants/default-variant';
import { StorageService } from './storage.service';

@Injectable()
export class ScreenService {

  public defaultPaymentScreens: Variant = DEFAULT_VARIANT;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private api: string,
    private readonly store: Store<IAppState>,
    private readonly storageService: StorageService,
  ) {}

  public getVariant(browserId: string): Observable<Variant | null> {
    const body = {
      browser_id: browserId,
    };
    return this.httpClient
      .post<{ payload: { variant: Variant, ab_test_name: string,  ab_test_parameters: { ab_test_start_event: string } } }>(`${ this.api }/onboarding/variants/resolve`, body, { headers: { browser_id: browserId } })
      .pipe(
        map(data => {
          if (data.payload) {
            const variant =  new Variant(
              data.payload.variant.id,
              data.payload.variant.name,
              data.payload.variant.onboarding,
              data.payload.variant.payment_screen,
              data.payload.ab_test_name,
              data.payload.ab_test_parameters ? data.payload.ab_test_parameters.ab_test_start_event : '',
              data.payload.variant.parameters,
            );
            this.storageService.setVariant(variant);
            return variant;
          }
          return null;
        }),
        catchError(err => {
          this.store.dispatch(new UpdateVariant(this.addedVatToPaymentsScreens(DEFAULT_VARIANT)));
          return throwError(err);
        }),
      );
  }

  public getVariantByType(browserId: string, type: string): Observable<Variant | null> {
    const body = { browser_id: browserId };
    return this.httpClient
      .post<{ payload: { variant: Variant, ab_test_name: string, ab_test_parameters: { ab_test_start_event: string } } }>(`${ this.api }/onboarding/${ type }/variants/resolve`, body, { headers: { browser_id: browserId } })
      .pipe(
        map(data => {
          if (data.payload) {
            const variant =  new Variant(
              data.payload.variant.id,
              data.payload.variant.name,
              data.payload.variant.onboarding,
              data.payload.variant.payment_screen,
              data.payload.ab_test_name,
              data.payload.ab_test_parameters ? data.payload.ab_test_parameters.ab_test_start_event : '',
              data.payload.variant.parameters,
            );
            this.storageService.setVariant(variant);
            return variant;
          }
          return null;
        }),
        catchError(err => {
          this.store.dispatch(new UpdateVariant(this.addedVatToPaymentsScreens(DEFAULT_VARIANT)));
          return throwError(err);
        }),
      );
  }

  public getSpecialVariant(id: string): Observable<Variant> {
    return this.httpClient
      .get<{ payload: Variant }>(`${ this.api }/onboarding/variants/${id}`)
      .pipe(
        map(data => new Variant(
          data.payload.id,
          data.payload.name,
          data.payload.onboarding,
          data.payload.payment_screen,
          '',
          '',
          data.payload.parameters,
          )),
        catchError(err => {
          this.store.dispatch(new UpdateVariant(this.addedVatToPaymentsScreens(DEFAULT_VARIANT)));
          return throwError(err);
        }),
      );
  }

  public getSpecialVariantAndChange(id: string): Observable<Variant> {
    return this.httpClient
      .get<{ payload: Variant }>(`${ this.api }/onboarding/variants/${id}`)
      .pipe(
        map(data => new Variant(
          data.payload.id,
          data.payload.name,
          data.payload.onboarding,
          data.payload.payment_screen,
          '',
          '',
          data.payload.parameters,
        )),
        catchError(err => {
          this.store.dispatch(new UpdateAndChangeVariant(DEFAULT_VARIANT));
          return throwError(err);
        }),
      );
  }

  public getDefaultVariant(): Observable<Variant> {
    return of(this.mockLoadVariant());
  }

  public mockLoadVariant(): Variant {
    return this.defaultPaymentScreens;
  }

  private addedVatToPaymentsScreens(variant: Variant | null): Variant | null {
    if (!variant) {
      return null;
    }
    const paymentScreens = variant.payment_screen.screens ?
      variant.payment_screen.screens!.map(item => {
        return { order: item.order, info: item.info };
      }) : variant.payment_screen.parameters.prevScreens!;

    variant.payment_screen.parameters.prevScreens = paymentScreens!.map(screen => {
      if (screen.info.blocks) {
        screen.info.parameters.blocks = screen.info.blocks.map((item: any) => {
          item.block.parameters = item.block.parameters ? item.block.parameters : {};
          item.block.parameters.vats = variant.parameters.vats ? variant.parameters.vats : {};
          return item;
        });
      }
      screen.info.parameters.vats = variant.parameters.vats ? variant.parameters.vats : {};
      return screen;
    });

    variant.payment_screen.screens = variant.payment_screen.parameters.prevScreens;
    return variant;
  }
}
