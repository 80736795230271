import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { PaymentViewTypesEnum } from '../enums/payment-view-types.enum';
import { UrlData } from '../models/url-data';
import { UrlChildData } from '../models/url-data-child';

@Injectable()
export class UrlDataService {
  public initialParams(activatedRouteStart: ActivatedRouteSnapshot, urlStart: string, data: UrlData): UrlData {

    const activatedRoute = Object.assign({}, activatedRouteStart);

    const newData: any = Object.assign({}, data);

    newData.url = this.getPaymentScreenType(`${ urlStart }`);

    const childName = activatedRoute.queryParamMap.get('name') || null;

    const childAge = activatedRoute.queryParamMap.get('age') || null;

    const childPrograms =
      activatedRoute.queryParamMap.get('programs') ? activatedRoute.queryParamMap.get('programs')!.replace('%20', ' ') : null;

    newData.type = 'alone';
    const temporaryType = activatedRoute.queryParamMap.get('type');
    if (temporaryType === 'alone' || temporaryType === 'bundle') {
      newData.type = temporaryType;
    }

    newData.typePrice =  'number';
    const temporaryTypePrice = activatedRoute.queryParamMap.get('price');
    if (temporaryTypePrice === 'number' || temporaryTypePrice === 'percent' || temporaryTypePrice === 'one') {
      newData.typePrice = temporaryTypePrice;
    }

    newData.app =  'lg';
    const temporaryApp =  activatedRoute.queryParamMap.get('app');
    if (temporaryApp === 'lg' || temporaryApp === 'bs' || temporaryApp === 'e4k') {
      newData.app = temporaryApp;
    }

    newData.child = new UrlChildData(childAge, childName, childPrograms);

    return newData;
  }

  public changeLoading(data: UrlData, loading: boolean): UrlData {
    const newData = Object.assign({}, data);
    newData.loading = loading;
    return newData;
  }

  public changeType(data: UrlData, projects: string[]): UrlData {
    const newData: any = Object.assign({}, data);
    const type = projects.length === 3 ? 'bundle3' :  projects.length === 4 ? 'bundle' : 'alone';
    newData.type = type;
    return newData;
  }

  private getPaymentScreenType(url: string): PaymentViewTypesEnum {
    if (url.indexOf('/login') !== -1) {
      return PaymentViewTypesEnum.LOGIN;
    }
    if (url.indexOf('/checkout') !== -1) {
      return PaymentViewTypesEnum.CHECKOUT;
    }
    return PaymentViewTypesEnum.PAY;
  }
}
