import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/common/services/storage.service';

import { Payment } from '../models/payment';

declare const fbq: any;

@Injectable()
export class FacebookPixelService {

  constructor(
    private storageService: StorageService,
  ) { }

  public trackEvent(event: string, properties: object | null = null): void {
    if (typeof fbq === 'undefined') {
      return;
    }
    const eventID = this.storageService.getUserId();
    if (properties) {
      fbq('track', event, properties,  { eventID });
      return;
    }

    fbq('track', event, {}, { eventID });
  }

  public facebookPurchaseEvent(payment: Payment): void {
    // this.trackEvent('Purchase', { value: payment.value, currency: payment.currency });
    if (payment.trial) {
      this.trackEvent('StartTrial', { value: payment.value, currency: payment.currency, predicted_ltv: '0.00' });
    }
  }
}
