import { EmailTypeEnum, PaymentTypeEnum, Screen, ScreenEmail, ThanksTypeEnum } from 'src/app/common/onboarding/public-api';

import { Variant } from './../models/variant';
import { ADDITINAL_PACKAGES } from './additional-packages';

export const WORKSHEETS_VARIANT: Variant = {
  id: '',
  name: 'Worksheets variant',
  abtest: 'Worksheets ABtest',
  ab_test_start_event: '',
  parameters: {
    ab_test_start_name: '',
  },
  onboarding: {
    id: '',
    name: 'Default onboarding',
    screens: [],
  },
  payment_screen: {
    id: '',
    name: 'Test',
    locked: false,
    screens: null,
    parameters: {
      needApple: true,
      paymaxis: false,
      prevScreens: [
        {
          order: 1,
          info: new Screen('1', EmailTypeEnum.EMAIL_PROFIT, new ScreenEmail('Email', 'blogger_enterEmail', '')),
        },
        {
          order: 2,
          info: new Screen('2', PaymentTypeEnum.WORKSHEETS, {
            name: '',
            additionalPackages: [ADDITINAL_PACKAGES[8], ADDITINAL_PACKAGES[1], ADDITINAL_PACKAGES[0], ADDITINAL_PACKAGES[7]],
            secondPackages: [],
            packName: 'add_printables',
            methodsType: 'THREE',
          },
          ),
        },
        {
          order: 3,
          info: new Screen('3', ThanksTypeEnum.AFTER_WORKSHEETS, {}),
        },
      ],
    },
  },
};
