import { AdditionPurchaseTypeEnum } from './../enums/addition-purchase.enum';
import { BeforePaymentTypeEnum } from './../enums/before-payment-types.enum';
import { ThanksTypeEnum } from './../enums/thanks-types.enum';
import { CrossGradeTypeEnum } from './../enums/cross-grade-types.enum';
import { PaymentTypeEnum } from './../enums/payment-types.enum';
import { EmailTypeEnum } from '../enums/email-types.enum';
import { LandingTypeEnum } from '../enums/landing-types.enum';
import { AdditionTypeEnum } from '../enums/addition-types.enum';
import { SpecialTypeEnum } from '../enums/special-types.enum';
import { AfterCancelType, ErrorTypeEnum } from '../public-api';

export type ScreenTypeEnum =
  LandingTypeEnum |
  EmailTypeEnum |
  PaymentTypeEnum |
  AdditionTypeEnum |
  CrossGradeTypeEnum |
  BeforePaymentTypeEnum |
  AdditionPurchaseTypeEnum |
  SpecialTypeEnum |
  ErrorTypeEnum |
  ThanksTypeEnum |
  EmailTypeEnum |
  AfterCancelType;

export class Screen {
  constructor(
    public id: string,
    public type: ScreenTypeEnum,
    public parameters: any,
    public blocks?: {
      block: any,
      order: number,
    }[],
    public locked?: boolean,
    public updated_at?: number,
  ) {
  }
}
