import { Injectable } from '@angular/core';

declare const qp: any;

@Injectable()
export class QuoraService {

  constructor() { }

  public trackEvent(event: string, properties: object | null = null): void {
    if (typeof qp === 'undefined') {
      return;
    }
    if (properties) {
      qp('track', event, properties);
      return;
    }

    qp('track', event);
  }
}
