import { EQuizActions, QuizActions } from '../actions/quiz.actions';
import { initialQuizState, IQuizState } from '../states/quiz.state';

export function quizReducers(
  state = initialQuizState,
  action: QuizActions,
): IQuizState {
  switch (action.type) {
    case EQuizActions.UpdateQuiz: {
      if (!!state.subjects.find(item => item.subject === action.subject)) {
        const others = state.subjects.filter(item => item.subject !== action.subject);
        const current: {subject: string, result: number, total: number} = JSON.parse(JSON.stringify(state.subjects.find(item => item.subject === action.subject)!));
        current.result += action.result;
        current.total += action.total;
        return {
          ...state,
          subjects: [...others, current],
        };
      }
      const {
        subject,
        result,
        total,
      } = action;
      return {
        ...state,
        subjects: [...state.subjects, { subject, result, total }],
      };
    }

    case EQuizActions.UpdateQuizAnswer: {
      const {
        subject,
        answer,
      } = action;
      return {
        ...state,
        answers: [...state.answers, { subject, answer }],
      };
    }

    default : return state;
  }
}
