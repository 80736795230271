import { IPaymentLinkState, initialPaymentLinkState } from '../states/payment-link.state';

import { EPaymentLinkActions, PaymentLinkActions } from '../actions/payment-link.actions';

export function paymentLinkReducers(
  state = initialPaymentLinkState,
  action: PaymentLinkActions,
): IPaymentLinkState {
  switch (action.type) {
    case EPaymentLinkActions.AddCardLinkSuccess: {
      return {
        ...state,
        cardLinks: [action.payload],
      };
    }

    case EPaymentLinkActions.AddPaypalLinkSuccess: {
      return {
        ...state,
        paypalLinks: [action.payload],
      };
    }

    case EPaymentLinkActions.ClearPaymentLinkSuccess: {
      return {
        ...state,
        cardLinks: [],
        paypalLinks: [],
      };
    }

    default : return state;
  }
}
